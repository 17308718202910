import { prepareFinalObject } from "../ui-redux/screen-configuration/actions";
import { webSocketPrepareFinalObject } from "../ui-redux/websocket-screen-configuration/actions";

export const addQueryArg = (url = "", queries = []) => {
  const urlParts = url.split("?");
  const path = urlParts[0];
  let queryParts = urlParts.length > 1 ? urlParts[1].split("&") : [];
  queries.forEach(query => {
    const key = query.key;
    const value = query.value;
    const newQuery = `${key}=${value}`;
    queryParts.push(newQuery);
  });
  const newUrl = path + "?" + queryParts.join("&");
  return newUrl;
};

export const getUrlParameterValue = key => {
  let params = new URL(document.location).searchParams;
  let value = params.get(key);
  return value;
};

export const age = dateString => {
  let birth = new Date(dateString);
  let now = new Date();
  let beforeBirth =
    (() => {
      birth.setDate(now.getDate());
      birth.setMonth(now.getMonth());
      return birth.getTime();
    })() < birth.getTime()
      ? 0
      : 1;
  return now.getFullYear() - birth.getFullYear() - beforeBirth;
};

export const getQueryArg = (url, name) => {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const svgToDataUri = svgStr => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  const header = "data:image/svg+xml;charset=utf8,";
  const dataUrl = header + encoded;

  return dataUrl;
};
export const svgReplaceColor = (svgStr, color, color1) => {
  svgToDataUri(svgStr.replace(/#xxxxxx1/g, color1));
  return svgToDataUri(svgStr.replace(/#xxxxxx/g, color));
};

export const mapDispatchToProps = dispatch => {
  return {
    setAppData: (jsonPath, data) => {
      dispatch(prepareFinalObject(jsonPath, data));
    },
    setSocketData: (jsonPath, data) => {
      dispatch(webSocketPrepareFinalObject(jsonPath, data));
    }
  };
};

export let snackbarObj = {};
snackbarObj.open = true;
snackbarObj.variant = "error";

export const convertDecimalPoints = (num, decimalPoint = 2) => {
  num = parseFloat(num);
  return num > 0 ? num.toFixed(decimalPoint) : num;
};

export const getPredicationValue = value => {
  const predicationsValues = {
    buy: 600,
    strong_buy: 750,
    sell: 300,
    strong_sell: 150,
    neutral: 450
  };
  return predicationsValues[value];
};

export const getPredicationLabel = value => {
  const predicationsValues = {
    buy: "BUY",
    strong_buy: "STRONG BUY",
    sell: "SELL",
    strong_sell: "STRONG SELL",
    neutral: "NEUTRAL"
  };
  return predicationsValues[value];
};

export const getAuthInfo = () => {
  let authDetails;
  try {
    authDetails = JSON.parse(localStorage.getItem("apiResponse")) || {};
  } catch (e) {
    authDetails = {};
  }
  return authDetails;
};

export const stocks = {
  nse: [
    "ASIANPAINT",
    "ULTRACEMCO",
    "SHREECEM",
    "HCLTECH",
    "KOTAKBANK",
    "ICICIBANK",
    "RELIANCE",
    "WIPRO",
    "IOC",
    "TCS",
    "GAIL",
    "JSWSTEEL",
    "BAJFINANCE",
    "TECHM",
    "UPL",
    "HDFCLIFE",
    "BHARTIARTL",
    "DRREDDY",
    "BRITANNIA",
    "INFY",
    "EICHERMOT",
    "TATASTEEL",
    "CIPLA",
    "BAJAJFINSV",
    "BAJAJ.AUTO",
    "INDUSINDBK",
    "POWERGRID",
    "DIVISLAB",
    "SBIN",
    "GRASIM",
    "MARUTI",
    "ITC",
    "BPCL",
    "SBILIFE",
    "SUNPHARMA",
    "HINDALCO",
    "HDFCBANK",
    "NESTLEIND",
    "COALINDIA",
    "HDFC",
    "HEROMOTOCO",
    "NTPC",
    "HINDUNILVR",
    "TATAMOTORS",
    "AXISBANK",
    "ONGC",
    "ADANIPORTS",
    "TITAN",
    "LT"
  ]
};
