import React, { Fragment } from "react";
import "./index.css";
const ComponentLoader = () => {
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1
      }}
    >
      <div className={"loader"} />
    </div>
  );
};

export default ComponentLoader;
