import axios from "axios";
import { addQueryArg } from "./commons";
import { prepareFinalObject } from "../ui-redux/screen-configuration/actions";
import createStore from "../ui-redux/store";
import backendHosts from "./apiConfig.js";
const { store } = createStore();

export const getToken = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  return userInfo.session_id;
};

let axiosInstances = {
  instanceOne: axios.create({
    baseURL: backendHosts.AUTH,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceTwo: axios.create({
    baseURL: backendHosts.STOCKRECOMMENDATION,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceThree: axios.create({
    baseURL: backendHosts.EVENT,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceFour: axios.create({
    baseURL: backendHosts.ACWI,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceFive: axios.create({
    baseURL: backendHosts.NEWS,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceSeven: axios.create({
    baseURL: backendHosts.MISCELLANEOUS,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceEigth: axios.create({
    baseURL: backendHosts.PORTFOLIO,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceTen: axios.create({
    baseURL: backendHosts.CALCULATORS,

    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceEleven: axios.create({
    baseURL: backendHosts.INDICATORS,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceTwelve: axios.create({
    baseURL: backendHosts.LIGHTNINGDASH,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceThirteen: axios.create({
    baseURL: backendHosts.CHATBOT,
    headers: {
      "Content-Type": "application/json"
    }
  }),

  instanceFourteen: axios.create({
    baseURL: backendHosts.MISCELLANEOUS,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceFifteen: axios.create({
    baseURL: backendHosts.OVERVIEWMARKET,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceSixteen: axios.create({
    baseURL: backendHosts.PREDICTIONRESULT,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceEighteen: axios.create({
    baseURL: backendHosts.CALANDER,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceNineteen: axios.create({
    baseURL: backendHosts.LCD,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instanceEighteen_1: axios.create({
    baseURL: backendHosts.CALANDER,
    headers: {
      "Content-Type": "application/json"
    }
  }),
  instancePay: axios.create({
    baseURL: backendHosts.PAYMENT,
    headers: {
      "Content-Type": "application/json"
    }
  })
};

const wrapRequestBody = requestBody => {
  return requestBody;
};

export const httpRequest = async ({
  method = "post",
  endPoint,
  queryObject = [],
  requestBody = {},
  instance = "instanceOne",
  hasSpinner = true,
  contentType = "application/json",
  headers = {}
}) => {
  if (hasSpinner) {
    store.dispatch(prepareFinalObject("spinner", true));
  }
  let apiError = "Api Error";

  var headerConfig = {
    headers
  };
  // const session_id = getToken();
  if (contentType) {
    switch (contentType) {
      case "application/x-www-form-urlencoded":
        var params = new URLSearchParams();
        headerConfig.headers["Content-Type"] = contentType;
        // if (session_id) {
        //   params.append("session.id", session_id);
        //   params.append("session_id", session_id);
        // }
        for (var variable in requestBody) {
          if (requestBody.hasOwnProperty(variable)) {
            params.append(variable, requestBody[variable]);
          }
        }
        requestBody = params;
        break;
      default:

      // requestBody.session_id = session_id;
      // headers["session_id"] = session_id;
      // requestBody["session.id"] = session_id;
    }
  }

  endPoint = addQueryArg(endPoint, queryObject);
  var response;
  try {
    switch (method) {
      case "post":
        response = await axiosInstances[instance].post(
          endPoint,
          wrapRequestBody(requestBody),
          headerConfig
        );
        break;

      case "delete":
        response = await axiosInstances[instance].delete(
          endPoint,
          headerConfig
        );

      case "put":
        response = await axiosInstances[instance].put(
          endPoint,
          wrapRequestBody(requestBody),
          headerConfig
        );

      default:
        response = await axiosInstances[instance].get(endPoint, headerConfig);
    }
    const responseStatus = parseInt(response.status, 10);
    if (hasSpinner) {
      store.dispatch(prepareFinalObject("spinner", false));
    }
    if (
      responseStatus === 200 ||
      responseStatus === 201 ||
      responseStatus === 202
    ) {
      return response.data;
    }
  } catch (error) {
    if (error && error.response && error.response.status) {
      // const { status } = error.response;
      if (hasSpinner) {
        store.dispatch(prepareFinalObject("spinner", false));
      }
      // if (status === 400 || status === 401) {
      // window.localStorage.clear();
      // window.location.reload();
      return error.response.data;
      // }
      // if (status === 401) {

      // }
    }

    if (hasSpinner) {
      store.dispatch(prepareFinalObject("spinner", false));
    }
  }
  if (hasSpinner) {
    store.dispatch(prepareFinalObject("spinner", false));
  }
  return apiError;
};
