export default {
  typography: {
    // In Japanese the characters are usually larger.
    fontSize: 12,
    fontFamily: "OpenSans-Regular"
  },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
    secondary: {
      main: "#25273e",
      contrastText: "#fff"
    }
  }
  // overrides: {
  //   // Style sheet name ⚛️
  //   MuiCardContent: {
  //     // Name of the rule
  //     root: {
  //       // Some CSS
  //       padding: '16px !important',
  //     },
  //   },
  // }
};
