import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = {
  rootblur: {
    backgroundColor: "#rgba(0, 0, 0, 0.16)",
    backdropFilter: "blur(30px)"
  }
};

const DialogTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="p">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CustomDialog extends React.Component {
  render() {
    const {
      open,
      handleClose,
      title,
      subTitle,
      content,
      classes,
      maxWidth = "xs"
    } = this.props;

    return (
      <div>
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="max-width-dialog-title"
          maxWidth={maxWidth}
          fullWidth
          BackdropProps={{
            classes: {
              root: classes.rootblur
            }
          }}
          PaperProps={{
            style: {
              background: "linear-gradient(#875f9a,#4f3161)",
              justifyContent: "center",
              borderRadius: 20,
              overflow: "inherit"
            }
          }}
        >
          <DialogTitle id="max-width-dialog-title" onClose={handleClose}>
            <Grid container align="center">
              <Grid item md={1} xs={1}></Grid>
              <Grid item md={10} xs={10} style={{ padding: "5px" }}>
                {title}
                {subTitle}
              </Grid>

              <Grid item md={1} xs={1}></Grid>
            </Grid>
          </DialogTitle>
          <Grid
            container
            align="center"
            justify="center"
            style={{ padding: "0px 16px 16px 16px" }}
          >
            <Grid item md={10} xs={10}>
              {content}
            </Grid>
          </Grid>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CustomDialog);
