import * as screenActionTypes from "./actionTypes";
import { webSocketPrepareFinalObject } from "./utils";

const getUserInfo = () => {
  const userInfo = window.localStorage.getItem("userInfo");
  return userInfo ? JSON.parse(userInfo) : {};
};

const intialState = {
  preparedFinalObject: {
    snackbar: {
      open: false,
      variant: "success",
      message: ""
    },
    spinner: false,
    liveData: {
      stockList: [],
      socketsData: {}
    },
    overview: {
      tipsSockets: []
    },
    portfolio: {
      portFolioSockets: []
    },
    userInfo: getUserInfo()
  }
};

const webSocketScreenConfiguration = (state = intialState, action) => {
  switch (action.type) {
    case screenActionTypes.WEBSOCKET_PREPARE_FINAL_OBJECT:
      const updatedPreparedFinalObject = webSocketPrepareFinalObject(
        state.preparedFinalObject,
        action.jsonPath,
        action.value
      );
      return {
        ...state,
        preparedFinalObject: updatedPreparedFinalObject
      };

    case screenActionTypes.FETCH_FOOTER_SOCKET_DATA:
      const updatedPreparedData = { ...state.preparedFinalObject };
      updatedPreparedData.liveData.stockList = [...action.data];
      return {
        ...state,
        preparedFinalObject: {
          ...updatedPreparedData
        }
      };

    case screenActionTypes.FETCH_OVERVIEW_SOCKET_DATA:
      const updatedFinalData = { ...state.preparedFinalObject };
      updatedFinalData.overview.tipsSockets = [...action.data];
      return {
        ...state,
        preparedFinalObject: {
          ...updatedFinalData
        }
      };

    case screenActionTypes.FETCH_PORTFOLIO_SOCKET_DATA:
      const updatedData = { ...state.preparedFinalObject };
      updatedData.portfolio.portFolioSockets = action.data;
      return {
        ...state,
        preparedFinalObject: {
          ...updatedData
        }
      };
    default:
      return state;
  }
};

export default webSocketScreenConfiguration;
