import * as screenActionTypes from "./actionTypes";

export const webSocketPrepareFinalObject = (jsonPath, value) => {
  return {
    type: screenActionTypes.WEBSOCKET_PREPARE_FINAL_OBJECT,
    jsonPath,
    value
  };
};

export const persistSocketData = payload => {
  return dispatch => {
    dispatch({
      type: screenActionTypes.FETCH_FOOTER_SOCKET_DATA,
      data: payload
    });
  };
};

export const fethcOverviewTipsData = payload => {
  return dispatch => {
    dispatch({
      type: screenActionTypes.FETCH_OVERVIEW_SOCKET_DATA,
      data: payload
    });
  };
};

export const fethcPortFolioTipsData = payload => {
  return dispatch => {
    dispatch({
      type: screenActionTypes.FETCH_PORTFOLIO_SOCKET_DATA,
      data: payload
    });
  };
};
