let backendHosts = {};
const hostname = window && window.location && window.location.hostname;
if (
  hostname.includes("bf-team-bf-frontend-portal-staging.bullforce.co") ||
  hostname === "localhost"
) {
  backendHosts.AUTH = "https://auth-service.bf-team.bullforce.co";
  backendHosts.PREDICTIONRESULT =
    "https://bf-team-prediction-result-reporter-staging.bullforce.co";
  backendHosts.CALANDER = "https://admin-miscellaneous.bf-team.bullforce.co/";
  //"https://bf-team-admin-miscellaneous-micro-service.bullforce.co";
  backendHosts.CALANDER1 = "https://admin-miscellaneous.bf-team.bullforce.co/";
  //"https://web771-oscarfxtrade-backend.oscarfxtrade.com";

  backendHosts.STOCKRECOMMENDATION =
    "https://stock-recommendation.bf-team.bullforce.co/";
  //"https://bf-team-stock-recommendation-micro-service-staging.bullforce.co";
  backendHosts.EVENT =
    "https://bf-team-event-micro-service-staging.bullforce.co";
  backendHosts.ACWI = "https://bf-team-acwi-micro-service-staging.bullforce.co";
  backendHosts.NEWS = "https://bf-team-news-micro-service-staging.bullforce.co";
  backendHosts.MISCELLANEOUS =
    "https://bf-team-miscellaneous-micro-service-staging.bullforce.co";
  backendHosts.PORTFOLIO =
    "https://portfolio-micro-service.bf-team.bullforce.co/";
  //"https://bf-team-portfolio-micro-service-staging.bullforce.co";
  backendHosts.CALCULATORS =
    "https://bf-team-calculator-facade-micro-service-staging.bullforce.co";
  backendHosts.INDICATORS =
    "https://bf-team-indicators-micro-service-staging.bullforce.co";
  backendHosts.LIGHTNINGDASH =
    "https://bf-team-lightning-dashboard-service-staging.bullforce.co";
  backendHosts.CHATBOT = "https://bf-team-bf-chatbot-production.bullforce.co";
  backendHosts.WSQUOTESOCKETURL =
    "wss://bf-team-realtime-quote-micro-service-staging.bullforce.co/ws-quote";
  backendHosts.WSLIGHTENINGDASHBOARD =
    "wss:///bf-team-lightning-dashboard-service-staging.bullforce.co/ws-lightning-dashboard";
  backendHosts.WSPORTFOLIO =
    "wss://bf-team-quote-micro-service-staging.bullforce.co:/ws-quote";
  backendHosts.OVERVIEWMARKET =
    "https://bf-team-market-data-dashboard-service-staging.bullforce.co";
  backendHosts.PAYMENT =
    "https://bf-team-payment-gateway-micro-service-staging.bullforce.co";
} else {
  backendHosts.CALANDER = "https://admin-miscellaneous.bf-team.bullforce.co/";
  //"https://bf-team-admin-miscellaneous-micro-service.bullforce.co";
  backendHosts.CALANDER1 = "https://admin-miscellaneous.bf-team.bullforce.co/";

  backendHosts.AUTH = "https://auth-service.bf-team.bullforce.co";
  backendHosts.PREDICTIONRESULT =
    "https://bf-team-prediction-result-reporter.bullforce.co";
  backendHosts.STOCKRECOMMENDATION =
    "https://stock-recommendation.bf-team.bullforce.co/";
  //  "https://bf-team-stock-recommendation-micro-service.bullforce.co";
  backendHosts.EVENT = "https://bf-team-event-micro-service.bullforce.co";
  backendHosts.ACWI = "https://bf-team-acwi-micro-service.bullforce.co";
  backendHosts.NEWS = "https://bf-team-news-micro-service.bullforce.co";
  backendHosts.MISCELLANEOUS =
    "https://bf-team-miscellaneous-micro-service.bullforce.co";
  backendHosts.PORTFOLIO =
    "https://portfolio-micro-service.bf-team.bullforce.co/";
  //"https://bf-team-portfolio-micro-service.bullforce.co";
  backendHosts.CALCULATORS =
    "https://bf-team-calculator-facade-micro-service.bullforce.co";
  backendHosts.INDICATORS =
    "https://bf-team-indicators-micro-service.bullforce.co";
  backendHosts.LIGHTNINGDASH =
    "https://bf-team-lightning-dashboard-service.bullforce.co";
  backendHosts.CHATBOT = "https://bf-team-bf-chatbot-production.bullforce.co";
  backendHosts.WSQUOTESOCKETURL =
    "wss://bf-team-realtime-quote-micro-service.bullforce.co/ws-quote";
  backendHosts.WSLIGHTENINGDASHBOARD =
    "wss://bf-team-lightning-dashboard-service.bullforce.co/ws-lightning-dashboard";
  backendHosts.WSPORTFOLIO =
    "wss://bf-team-quote-micro-service.bullforce.co:/ws-quote";
  backendHosts.OVERVIEWMARKET =
    "https://bf-team-market-data-dashboard-service.bullforce.co";

  backendHosts.PAYMENT = "https://payment-gateway.bullforce.co";
}

export default backendHosts;
