import React from "react";
// import { Route } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

const Landing = Loadable({
  loader: () => import("../ui-pages/Landing/index"),
  loading: Loading
});

const UserHome = Loadable({
  loader: () => import("../ui-pages/UserHome"),
  loading: Loading
});

const CFDRouting = Loadable({
  loader: () => import("../ui-pages/cfdTrading"),
  loading: Loading
});

const AboutUs = Loadable({
  loader: () =>
    import("../ui-pages/UserHome/components/Content/components/AboutUs"),
  loading: Loading
});

const PrivacyPolicy = Loadable({
  loader: () => import("../ui-pages/Privacy-page/privacyPage"),
  loading: Loading
});

const RiskDisclosue = Loadable({
  loader: () => import("../ui-pages/Risk_disclosure/risk-disclosure"),
  loading: Loading
});

const TermsConditions = Loadable({
  loader: () => import("../ui-pages/Terms-Conditions/terms-conditions"),
  loading: Loading
});

const PrivacyPolicyAI = Loadable({
  loader: () => import("../ui-pages/Privacy-Policy-AI-Pred/privacyPolicy-AI"),
  loading: Loading
});

const TermsConditionsAI = Loadable({
  loader: () =>
    import("../ui-pages/Terms-Conditions-AI-Pred/terms-Conditions-AI"),
  loading: Loading
});

const RefundPolicyAI = Loadable({
  loader: () => import("../ui-pages/Refund-policy/refundpolicy"),
  loading: Loading
});
const lcd = Loadable({
  loader: () => import("../ui-pages/lcd/lcd"),
  loading: Loading
});

const MainRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/user-home" component={UserHome} />
        <Route path="/user-cfd" component={CFDRouting} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/privacy-policy-cfd" component={PrivacyPolicy} />
        <Route path="/risk_disclosure" component={RiskDisclosue} />
        <Route path="/terms_and_conditions" component={TermsConditions} />
        <Route path="/refund-policy-ai-prediction" component={RefundPolicyAI} />
        <Route path="/lcd" component={lcd} />

        <Route
          path="/privacy-policy-ai-prediction"
          component={PrivacyPolicyAI}
        />
        <Route
          path="/terms_and_conditions-ai-prediction"
          component={TermsConditionsAI}
        />
      </Switch>
    </div>
  );
};

export default MainRoutes;
