import React from "react";
import { withRouter } from "react-router-dom";
import Snackbar from "./ui-containers/SnackBar";
import MainRoutes from "./ui-routes/MainRoutes";

import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import "./App.css";
import isEmpty from "lodash/isEmpty";
import {
  domain,
  region,
  appClientId,
  redirectURI
} from "./ui-config/userPoolDetails";
import { httpRequest } from "./ui-utils/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "./ui-containers/CustomDialog";
import { Typography, Button, Grid, withStyles } from "@material-ui/core";
import get from "lodash/get";
//import { analytics } from "./ui-config/firebase";
import createStore from "./ui-redux/store";
import {
  persistSocketData,
  webSocketPrepareFinalObject
} from "./ui-redux/websocket-screen-configuration/actions";
import { prepareFinalObject } from "./ui-redux/screen-configuration/actions";
import backendHosts from "./ui-utils/apiConfig";
import LoaderComp from "./ui-atoms/components/Loader";
import ComponentLoader from "./ui-atoms/components/ComponentLoader";

var StompJs = require("@stomp/stompjs");
var isSocketInitialized = false;

const styles = theme => ({
  regHead: {
    color: "#e8e828",
    lineHeight: "28px",
    fontSize: "21px",
    fontFamily: "Montserrat-Bold"
  },
  regSubTitle: {
    color: "#e4e4e4",
    lineHeight: "22px",
    fontSize: "16px",
    fontFamily: "Montserrat-Regular"
  },
  freeTrailButton: {
    borderRadius: 0,
    color: "#242430",
    textTransform: "none",
    lineHeight: "19px",
    fontSize: "14px",
    fontFamily: "Montserrat-Bold"
  }
});

const App = props => {
  const { spinner, openSubscriptionModal, errMsg, classes } = props;
  const [loader, setLoader] = React.useState(false);
  const [loadercat, setLoadercat] = React.useState(false);

  React.useEffect(() => {
    ipAddressResp();
    initializeSocket();
    setLoader(false);
    // analytics.logEvent("APP_LAUNCH");
    const loadertype = localStorage.getItem("loddertype");

    if (loadertype === "0") {
      setLoadercat(false);
    }
    if (loadertype === "1") {
      setLoadercat(true);
    }
  }, []);

  const subscribeNowBtn = () => {
    const { setAppData, history } = props;
    history.push("/user-home/my-account");
    setAppData(`openSubscriptionModal`, false);
  };

  const ipAddressResp = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch("https://ipapi.co/json/", requestOptions)
      .then(response => response.text())
      .then(result => {
        let ipAddress = JSON.parse(result);

        callBackForSocialLogin(ipAddress.ip);
      })
      .catch(error => console.log("error", error));
  };

  function search(source, name) {
    var results = [];
    var index;
    var entry;
    results = Object.keys(source);

    if (results[0] === "errors") {
      index = 0;
    }
    if (results[0] === "tokenApiResponse") {
      index = 1;
    }

    /* name = name.toUpperCase();
     console.log(source.length)
     console.log('Name', name)
     for (index = 0; index < source.length; ++index) {
       entry = source[index];
       console.log('entry', entry)
       if (entry && entry.name && entry.name.toUpperCase().indexOf(name) !== -1) {
         results.push(entry);
       }
     }
 */
    return index;
  }
  const getAuthInfo = () => {
    let authDetails;
    try {
      authDetails = JSON.parse(localStorage.getItem("apiResponse")) || {};
    } catch (e) {
      authDetails = {};
    }
    return authDetails;
  };

  const initializeSocket = () => {
    setLoader(true);
    const authDetails = getAuthInfo();
    if (!isSocketInitialized && authDetails && authDetails.access_token) {
      // Create WebSocket connection.
      const { persistSocketsData, setSocketData } = props;
      const quiteSocketUrl = backendHosts.WSQUOTESOCKETURL;

      const quoteSocketClient = new StompJs.Client({
        brokerURL: quiteSocketUrl,
        connectHeaders: {
          token: authDetails.access_token
        },
        debug: function(str) {},
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000
      });

      quoteSocketClient.onConnect = function(frame) {
        // Do something, all subscribes must be done is this callback
        // This is needed because this will be executed after a (re)connect
        const callbackForFooterStocksQuote = function(message) {
          // called when the quoteSocketClient receives a STOMP message from the server
          if (message.body) {
            const data = JSON.parse(message.body);
            const { store } = createStore();
            const liveData = get(
              store.getState(),
              "webSocketScreenConfiguration.preparedFinalObject.liveData.stockList",
              []
            );
            if (!isEmpty(data)) {
              let stockLists = [];
              stockLists = [...liveData];
              if (data?.type !== null) {
                stockLists.push({
                  label: data?.symbol,
                  value: data?.price?.toFixed(2) || 0,
                  percent: data?.day_percent_change?.toFixed(2) || 0,
                  image:
                    data?.price > Number(data?.day_previous_close)
                      ? "/../../ui-assets/images/uptrendIcon.svg"
                      : "/../../ui-assets/images/downtrendIcon.svg",
                  value1: data?.day_percent_change?.toFixed(2),
                  property: data?.type
                });
              }
              if (stockLists?.length > 0) {
                persistSocketsData(stockLists);
              }
            }
          } else {
          }
        };

        const callbackForOverviewQuote = function(message) {
          // called when the quoteSocketClient receives a STOMP message from the server
          if (message.body) {
            const { store } = createStore();
            const liveData = get(
              store.getState(),
              "webSocketScreenConfiguration.preparedFinalObject.overview.tipsSockets",
              []
            );
            const data = JSON.parse(message.body);
            if (!isEmpty(data)) {
              if (data?.symbol) {
                liveData[data?.symbol] = { ...data };
              }
              setSocketData("overview.tipsSockets", liveData);
            }
          } else {
          }
        };

        quoteSocketClient.subscribe(
          "/topic/general-tips-stocks-quote",
          callbackForOverviewQuote
        );

        // quoteSocketClient.subscribe(
        //   "/topic/footer-stocks-quote",
        //   callbackForFooterStocksQuote
        // );
      };

      quoteSocketClient.onStompError = function(frame) {
        // Will be invoked in case of error encountered at Broker
        // Bad login/passcode typically will cause an error
        // Complaint brokers will set `message` header with a brief message. Body may contain details.
        // Compliant brokers will terminate the connection after any error
      };

      quoteSocketClient.activate();

      //lighting dashboard sockets Integration

      //portfolio sockets Integration

      isSocketInitialized = true;
    }
  };

  const sosredirect = async accTkn => {
    const { history, setAppData } = props;

    //    history.push("/user-home");

    //window.location.reload(false);
    const authDetails = getAuthInfo();
    try {
      const logoutUser = await httpRequest({
        endPoint: `api/v1/user/logout`,
        method: "post",
        instance: "instanceOne",
        headers: {
          Authorization: `Bearer ${authDetails.access_token}`
        }
      });

      setAppData("user.logoutUser", logoutUser);

      localStorage.setItem("vaidityResponse", "");
      localStorage.setItem("isLoggedIn", false);
      setAppData(`userInfo`, {});

      setAppData(`openSubscription`, false);
      // history.push("/user-home");
      window.location.href =
        "https://" +
        domain +
        ".auth." +
        region +
        ".amazoncognito.com/logout?" +
        "client_id=" +
        appClientId +
        "&logout_uri=" +
        redirectURI;
    } catch {}

    return;
  };
  const callValidateapi = async accTkn => {
    const { history, setAppData } = props;

    setAppData("spinner", true);
    await httpRequest({
      endPoint: `/api/v1/token/_validate?access_token=${accTkn}`,
      method: "post",
      instance: "instanceOne"
    })
      .then(response => {
        if (response.errors) {
          let msg = "";
          let code = "";
          response.errors.forEach(err => {
            msg = `${err.message}`;
            code = `${err.code}`;
          });
          if (code === "BULLFORCE-AMS-1007" || code === "BULLFORCE-AMS-1008") {
            setAppData(`errMsg`, msg);
            setAppData(`openSubscriptionModal`, true);
          }
        } else {
          localStorage.setItem("vaidityResponse", JSON.stringify(response));
          history.push("/user-home/dashboard");
          localStorage.setItem("loddertype", "0");
          localStorage.setItem("isLoggedIn", true);
          window.location.reload();
        }
      })
      .catch(errors => {
        let msg = "";
        let code = "";
        errors.forEach(err => {
          msg = `${err.message}`;
          code = `${err.code}`;
        });
        if (code === "BULLFORCE-AMS-1007" || code === "BULLFORCE-AMS-1008") {
          setAppData(`errMsg`, msg);
          setAppData(`openSubscriptionModal`, true);
        }
      });
  };

  const callBackForSocialLogin = async ipRes => {
    const { setAppData, history } = props;

    var urlParams = new URLSearchParams(window.location.search);
    var code = urlParams.get("code");
    const authDetails = getAuthInfo();

    if (code && !authDetails.access_token) {
      setAppData("spinner", true);
      fetch(
        "https://" +
          domain +
          ".auth." +
          region +
          ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=" +
          appClientId +
          "&redirect_uri=" +
          redirectURI +
          "&code=" +
          code,
        {
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
          let tokens = data;
          fetch(
            "https://" +
              domain +
              ".auth." +
              region +
              ".amazoncognito.com/oauth2/userInfo",
            {
              method: "post",
              headers: {
                authorization: "Bearer " + tokens.access_token
              }
            }
          )
            .then(response => {
              return response.json();
            })
            .then(data => {
              // Display user information

              httpRequest({
                endPoint: "api/v1/user/social-login",
                method: "post",
                instance: "instanceOne",
                requestBody: {
                  emailAddress: data.email,
                  username: data.username,
                  firstName: data.name,
                  lastName: "",
                  clientId: "bullforce-portal",
                  clientSecrete: "Passw0rd#",
                  ipAddress: ipRes
                }
              })
                .then(apiResponse => {
                  if (search(apiResponse, "") == 0) {
                    const item = {
                      code: apiResponse.errors[0].code
                    };
                    localStorage.setItem("apiResponse", JSON.stringify(item));
                    localStorage.setItem("apiResponse11", JSON.stringify(item));
                    sosredirect(null);
                  }
                  if (search(apiResponse, "") == 1) {
                    localStorage.setItem(
                      "apiResponse",
                      JSON.stringify(apiResponse.tokenApiResponse)
                    );
                    setAppData("userInfo", apiResponse.tokenApiResponse);
                    localStorage.setItem("loddertype", "1");
                    callValidateapi(
                      apiResponse?.tokenApiResponse?.access_token
                    );
                  }

                  console.log("apiResponse", apiResponse.tokenApiResponse);

                  /* if (apiResponse.errors[0].code.length > 0) {
                   
                   }*/
                  //if (apiResponse.tokenApiResponse.length > 0) {
                  /*/   localStorage.setItem(
                       "apiResponse",
                       JSON.stringify(apiResponse.tokenApiResponse)
                     );
                     setAppData("userInfo", apiResponse.tokenApiResponse);
                     localStorage.setItem("loddertype", "1");
                     callValidateapi(
                       apiResponse?.tokenApiResponse?.access_token
                     );*/
                  // }
                })
                .catch(apiResponse => {
                  //callValidateapi(null);
                });
            });
        });
    }
  };
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (isLoggedIn !== "true") {
    return (
      <LoadingOverlay
        //spinner={<LoaderComp />}
        //spinner={loadercat ? <ComponentLoader /> : <LoaderComp />}
        spinner={loadercat ? <ComponentLoader /> : <ComponentLoader />}
        styles={{
          overlay: base => ({
            ...base,
            backgroundColor: "rgba(0, 0, 0, 0.16)",
            backdropFilter: "blur(0.5px)"
          })
        }}
        active={spinner}
      >
        <div>
          <CustomDialog
            style={{ background: "red !important" }}
            open={openSubscriptionModal}
            title={
              <Typography
                variant="subtitle1"
                style={{}}
                className={classes.regHead}
              >
                End of Subscription
              </Typography>
            }
            subTitle={
              <Typography
                variant="subtitle2"
                style={{ padding: "0% 16%" }}
                className={classes.regSubTitle}
              >
                {errMsg}
              </Typography>
            }
            content={
              <Grid>
                <Button
                  className={classes.freeTrailButton}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ marginBottom: "2%", backgroundColor: "#f4d553" }}
                  onClick={() => subscribeNowBtn()}
                >
                  Subscribe Now
                </Button>
              </Grid>
            }
          ></CustomDialog>
          <MainRoutes />
          <Snackbar />
        </div>
      </LoadingOverlay>
    );
  }

  return (
    <LoadingOverlay
      //spinner={loadercat ? <LoaderComp /> : <ComponentLoader />}
      spinner={loadercat ? <ComponentLoader /> : <ComponentLoader />}
      styles={{
        overlay: base => ({
          ...base,
          backgroundColor: "rgba(0, 0, 0, 0.16)",
          backdropFilter: "blur(0.5px)"
        })
      }}
      active={spinner}
    >
      <div>
        <CustomDialog
          style={{ background: "red !important" }}
          open={openSubscriptionModal}
          title={
            <Typography
              variant="subtitle1"
              style={{}}
              className={classes.regHead}
            >
              End of Subscription
            </Typography>
          }
          subTitle={
            <Typography
              variant="subtitle2"
              style={{ padding: "0% 16%" }}
              className={classes.regSubTitle}
            >
              {errMsg}
            </Typography>
          }
          content={
            <Grid>
              <Button
                className={classes.freeTrailButton}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                style={{ marginBottom: "2%", backgroundColor: "#f4d553" }}
                onClick={() => subscribeNowBtn()}
              >
                Subscribe Now
              </Button>
            </Grid>
          }
        ></CustomDialog>
        <MainRoutes />
        <Snackbar />
      </div>
    </LoadingOverlay>
  );
};

const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject = {} } = screenConfiguration;
  const {
    spinner = false,
    userInfo = {},
    openSubscriptionModal = false,
    errMsg
  } = preparedFinalObject;
  return { spinner, userInfo, openSubscriptionModal, errMsg };
};

const mapDispatchToProps = dispatch => {
  return {
    persistSocketsData: data => {
      dispatch(persistSocketData(data));
    },
    setSocketData: (jsonPath, value) => {
      dispatch(webSocketPrepareFinalObject(jsonPath, value));
    },
    setAppData: (jsonPath, data) => {
      dispatch(prepareFinalObject(jsonPath, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(App)));
