import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
//import Button from '@material-ui/core/Button';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../ui-utils/commons";
// import errorSnack from  "../../../public/ui-assets/images/error-snackbar.svg";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: CloseIcon,
  info: InfoIcon
};

const stylesOne = theme => ({
  snackbarRoot: {
    whiteSpace: "pre-line"
  },
  success: {
    backgroundColor: "#ffffff",
    borderLeft: `8px solid #0faa14`,
    flexWrap: "nowrap"
  },
  error: {
    backgroundColor: "#ffffff",
    borderLeft: "8px solid #fc4c4c",
    flexWrap: "nowrap",
    color: "#fc4c4c"
  },
  info: {
    backgroundColor: theme.palette.primary.dark
  },
  warning: {
    backgroundColor: "#ffffff",
    border: "4px solid orange"
  },
  icon: {
    fontSize: 20,
    color: "#333333"
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 8
    // color: "green"
  },
  message: {
    display: "flex",
    alignItems: "center",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "OpenSans-Bold"
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      classes={{ root: classes.snackbarRoot }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {/* <Icon className={classNames(classes.icon, classes.iconVariant)} /> */}
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(stylesOne)(MySnackbarContent);

const stylesTwo = theme => ({
  margin: {
    margin: 8
  }
});

class CustomizedSnackbars extends React.Component {
  handleClose = (event, reason) => {
    const { setAppData, snackbar } = this.props;
    if (reason === "clickaway") {
      return;
    }
    setAppData("snackbar", {
      open: false,
      variant: snackbar.variant,
      message: ""
    });
  };

  render() {
    const { snackbar = {} } = this.props;
    const { handleClose } = this;
    const mySnackbarObject = {
      variant: snackbar.variant,
      message: snackbar.message
    };
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <MySnackbarContentWrapper
            onClose={handleClose}
            {...mySnackbarObject}
          />
        </Snackbar>
      </div>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject } = screenConfiguration;
  const { snackbar = {} } = preparedFinalObject;
  return { snackbar };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesTwo)(CustomizedSnackbars));
